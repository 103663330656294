import { Button, Checkbox, Dialog, DialogContent, FormControlLabel, Link, TextField } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import FormData from 'form-data';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../../api/api';
import embedPicture from '../../assets/embed/design-selector-suntex@2x.png';
import mockUp from '../../assets/mockup/mockUp@2x.png';
import { useData } from '../DataProvider';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      flexGrow: 1,
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 200,
      },
    },
  },
  textField: {
    marginTop: 10,
    marginBottom: 10,
  },
  fontSize: {
    fontSize: '0.8em',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

export default function RegisterDialog({ margin }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const openRegister = new URLSearchParams(window.location.search).get('openRegister');

  const [checkboxState1, setCheckboxState1] = useState(false);
  const [loginState, setLoginState] = React.useState(false);
  const [loginError, setError] = useState(false);
  const [registerState, setRegisterState] = useState(false);
  const [isInvalidPassword, setIsinvalidPassword] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [helperTextPassword, setHelperTextPassword] = useState('');
  const [helperTextPasswordRepeat, setHelperTextPasswordRepeat] = useState('');
  const maxWidth = 'lg';
  const { userId, selectColor, userData, setUserId, queryParams } = useData();
  const [open, setOpen] = useState(!!openRegister);

  const isPhone = useMediaQuery({ maxWidth: 750 }) || window.innerHeight < 400;
  const { search } = useLocation();
  const logggedInUserId = new URLSearchParams(search).get('loggedInUserId');

  const [registerData, setRegisterData] = useState({
    salutation: '',
    firstNameRegistration: '',
    surName: '',
    emailAdressRegistration: '',
    passwordRegistration: '',
    passwordRegistrationRepeat: '',
    companyName: '',
    streetName: '',
    houseNumber: '',
    zipCode: '',
    city: '',
    country: '',
  });

  const [loginData, setLoginData] = useState({
    emailAdress: '',
    password: '',
  });

  const navigate = useNavigate();
  const isValidRegister = Object.values(registerData).every((o) => o !== '' && checkboxState1);
  const language = new URLSearchParams(window.location.search).get('lang') || localStorage.getItem('i18nextLng');

  const isValidSignIn = Object.values(loginData).every((o) => o !== '');

  async function handleRegister(e) {
    e.preventDefault();
    setRegisterState(true);

    const invalidPassword = checkPassword(registerData.passwordRegistration, registerData.passwordRegistrationRepeat);
    setIsinvalidPassword(invalidPassword);

    if (isValidRegister === true && !invalidPassword) {
      const { data } = await api.init();
      const localURI = new URLSearchParams(window.location.search).get('localURI') || window.location.href;

      const registerObject = new FormData();
      registerObject.append('user_pass', registerData.passwordRegistration);
      registerObject.append('email', registerData.emailAdressRegistration);
      registerObject.append('username', registerData.emailAdressRegistration);
      registerObject.append('display_name', registerData.emailAdressRegistration);
      registerObject.append('nonce', data.nonce);
      registerObject.append('notify', 'both');
      registerObject.append('insecure', 'cool');
      registerObject.append('parentUrl', localURI);

      console.log(language);

      try {
        const response = await api.register(registerObject);

        const formData = new FormData();
        formData.append('company_name', registerData.companyName);
        formData.append('company_address', `${registerData.streetName} ${registerData.houseNumber}`);
        formData.append('company_zip', registerData.zipCode);
        formData.append('company_city', registerData.city);
        formData.append('company_country', registerData.country);
        formData.append('contact_person_firstname', registerData.firstNameRegistration);
        formData.append('contact_person_lastname', registerData.surName);
        formData.append('setId', userData.data ? userData.data.data.userData.setId : 7468);
        formData.append('parentUserId', userData.data ? userData.data.data.userData.userId : '');
        formData.append('show_embedding_banner', userData.data.data.userData.show_embedding_banner === 'true');
        formData.append('userlng', language);

        if (userData.data) {
          formData.append('user_role', 'Fachhaendler');
          if (userData.data.data.userData.user_role === 'Sattler') {
            formData.append('user_role', 'Produzent');
          }
        }

        const newUserId = response.data.user_id;
        if (response) setUserId(newUserId);

        formData.append('user_id', newUserId);

        await api.postUserData(formData);

        localStorage.setItem('jwt', response.data.cookie);

        navigate(`/profile?${queryParams}&loggedInUserId=${newUserId}`);
      } catch (error) {
        // console.log(error);
        if (error && error.response && error.response.status === 404) {
          setShowAlert(true);
          setAlertText(error.response.data.error);
        }
      }
    }
  }

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoginState(true);

    const { data } = await api.login(loginData);

    if (data.status === 'error') {
      setError(true);
    } else if (isValidSignIn === true) {
      setError(false);

      localStorage.setItem('jwt', data.cookie);
      navigate(`/profile${queryParams}&loggedInUserId=${data.user.id}`);
    }
  };

  const handleChangeRegister = (e) => {
    const { name, value } = e.target;
    setRegisterData({ ...registerData, [name]: value });
  };

  const handleChangeSignIn = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };

  const checkIsValid = (fieldName) => fieldName === '' || fieldName === undefined;

  const checkPassword = (password, passwordRepeat) => {
    if (password === '' || password === undefined) {
      setHelperTextPassword(t('not_empty'));
      return true;
    }
    const regexPasswordLength = /.{8,}/; // test for at least 8 characters
    const regexPasswordContainsUpperCase = /[A-Z]/; // test for uppercase letter
    const regexPasswordContainsLowerCase = /[a-z]/; // test for lowercase letter
    const regexPasswordContainsNumber = /\d/; // test for number
    const regexPasswordContainsSpecialChar = /\W/; // test for special character

    if (!regexPasswordLength.test(password)) {
      setHelperTextPassword(t('mineight_charts'));
      return true;
    }

    if (!regexPasswordContainsUpperCase.test(password)) {
      setHelperTextPassword(t('min_upper'));
      return true;
    }

    if (!regexPasswordContainsLowerCase.test(password)) {
      setHelperTextPassword(t('min_lower'));
      return true;
    }

    if (!regexPasswordContainsNumber.test(password)) {
      setHelperTextPassword(t('min_number'));
      return true;
    }

    if (!regexPasswordContainsSpecialChar.test(password)) {
      setHelperTextPassword(t('min_special'));
      return true;
    }

    if (passwordRepeat === '' || passwordRepeat === undefined) {
      setHelperTextPasswordRepeat(t('not_empty'));
      return true;
    }
    if (password !== passwordRepeat) {
      setHelperTextPasswordRepeat(t('not_matching'));
      return true;
    }
    if (!regexPasswordLength.test(passwordRepeat)) {
      setHelperTextPasswordRepeat(t('mineight_charts'));
      return true;
    }

    if (!regexPasswordContainsUpperCase.test(passwordRepeat)) {
      setHelperTextPasswordRepeat(t('min_upper'));
      return true;
    }

    if (!regexPasswordContainsLowerCase.test(passwordRepeat)) {
      setHelperTextPasswordRepeat(t('min_lower'));
      return true;
    }

    if (!regexPasswordContainsNumber.test(passwordRepeat)) {
      setHelperTextPasswordRepeat(t('min_number'));
      return true;
    }

    if (!regexPasswordContainsSpecialChar.test(passwordRepeat)) {
      setHelperTextPasswordRepeat(t('min_special'));
      return true;
    }

    setHelperTextPassword('');
    setHelperTextPassword('');

    return false;
  };

  const handleClickOpen = () => {
    if (logggedInUserId) {
      navigate(`/profile?loggedInUserId=${logggedInUserId}&userId=${userId}`);
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const desktopStyles = {
    float: 'right',
    width: '150%',
  };

  const phoneStyles = {
    width: '60%',
  };

  return (
    <div id="registerDialog">
      <Paper
        onClick={handleClickOpen}
        square
        elevation={4}
        style={{
          marginTop: margin === 0 ? 0 : '2%',
          padding: '1.5%',
          cursor: 'pointer',
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} sm={8}>
            <Typography
              gutterBottom
              variant="h6"
              color="textSecondary"
              style={{ textTransform: 'uppercase', color: '#414141', fontSize: '1rem' }}
            >
              {t('sattler_text')}
            </Typography>
            <Typography variant="body2">{t('embed_text')}</Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Typography
              variant="h6"
              style={{
                color: selectColor,
                float: 'right',
                marginRight: isPhone && isPhone ? 0 : '10%',
              }}
            >
              {t('embed_now')}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={1}>
            <img style={isPhone && isPhone ? phoneStyles : desktopStyles} alt="" src={embedPicture} />
          </Grid>
        </Grid>
      </Paper>

      <Dialog open={open} onClose={handleClose} maxWidth={maxWidth}>
        <DialogContent>
          <Container>
            <div className={classes.root}>
              {showAlert && <Alert severity="error">{alertText}</Alert>}
              <Grid container spacing={3}>
                <Grid item xs={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
                  <Button onClick={handleClose} color="primary" style={{ float: 'right' }}>
                    <CloseIcon style={{ color: '#414141' }} />
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    {t('sattler_text')}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {t('sattler_detail_text')}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <form onSubmit={handleRegister}>
                    <Grid item xs={6}>
                      <div
                        style={{
                          fontWeight: 700,
                          textTransform: 'uppercase',
                        }}
                      >
                        {t('register')}
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        style={{ width: '90%', marginLeft: 0 }}
                        size="small"
                        error={registerState ? checkIsValid(registerData.salutation) : ''}
                      >
                        <InputLabel id="demo-simple-select-outlined-label">{t('salutation')}*</InputLabel>
                        <Select
                          id="salutation"
                          name="salutation"
                          labelId="demo-simple-select-outlined-label"
                          label={`${t('salutation')} * `}
                          error={registerState ? checkIsValid(registerData.salutation) : ''}
                          value={registerData.salutation}
                          onChange={handleChangeRegister}
                        >
                          <MenuItem value="Herr">Herr</MenuItem>
                          <MenuItem value="Frau">Frau</MenuItem>
                        </Select>
                        <FormHelperText>
                          {registerState && checkIsValid(registerData.salutation) ? t('not_empty') : ''}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="firstNameRegistration"
                        style={{ width: '90%', marginLeft: 0 }}
                        label={`${t('firstname')} * `}
                        name="firstNameRegistration"
                        variant="outlined"
                        size="small"
                        error={registerState ? checkIsValid(registerData.firstNameRegistration) : ''}
                        helperText={
                          registerState && checkIsValid(registerData.firstNameRegistration) ? t('not_empty') : ''
                        }
                        value={registerData.firstnameRegistration}
                        onChange={handleChangeRegister}
                        className={classes.textField}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="surName"
                        style={{ width: '90%', marginLeft: 0 }}
                        label={`${t('lastname')} * `}
                        name="surName"
                        variant="outlined"
                        size="small"
                        className={classes.textField}
                        error={registerState ? checkIsValid(registerData.surName) : ''}
                        helperText={registerState && checkIsValid(registerData.surName) ? t('not_empty') : ''}
                        value={registerData.surName}
                        onChange={handleChangeRegister}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        style={{ width: '90%', marginLeft: 0 }}
                        id="emailAdressRegistration"
                        name="emailAdressRegistration"
                        label={`${t('email')} * `}
                        variant="outlined"
                        className={classes.textField}
                        size="small"
                        error={registerState ? checkIsValid(registerData.emailAdressRegistration) : ''}
                        helperText={
                          registerState && checkIsValid(registerData.emailAdressRegistration) ? t('not_empty') : ''
                        }
                        value={registerData.emailAdressRegistration}
                        onChange={handleChangeRegister}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        style={{ width: '90%', marginLeft: 0 }}
                        id="passwordRegistration"
                        name="passwordRegistration"
                        label={`${t('password')} * `}
                        variant="outlined"
                        type="password"
                        className={classes.textField}
                        size="small"
                        error={isInvalidPassword}
                        helperText={helperTextPassword}
                        value={registerData.passwordRegistration}
                        onChange={handleChangeRegister}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        style={{ width: '90%', marginLeft: 0 }}
                        id="passwordRegistrationRepeat"
                        name="passwordRegistrationRepeat"
                        label={`${t('password_rep')} * `}
                        variant="outlined"
                        type="password"
                        className={classes.textField}
                        size="small"
                        error={isInvalidPassword}
                        helperText={helperTextPasswordRepeat}
                        value={registerData.passwordRegistrationRepeat}
                        onChange={handleChangeRegister}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <div
                        style={{
                          width: '90%',
                          marginLeft: 0,
                          fontWeight: 700,
                          marginTop: '2%',
                          marginBottom: '1%',
                        }}
                      >
                        <p>{t('company_data')}</p>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        style={{ width: '90%', marginLeft: 0 }}
                        id="companyName"
                        name="companyName"
                        label={`${t('company_name')} * `}
                        variant="outlined"
                        className={classes.textField}
                        size="small"
                        error={registerState ? checkIsValid(registerData.companyName) : ''}
                        helperText={registerState && checkIsValid(registerData.companyName) ? t('not_empty') : ''}
                        value={registerData.companyName}
                        onChange={handleChangeRegister}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        style={{ width: '90%', marginLeft: 0 }}
                        id="streetName"
                        name="streetName"
                        label={`${t('company_address')} * `}
                        variant="outlined"
                        className={classes.textField}
                        size="small"
                        error={registerState ? checkIsValid(registerData.streetName) : ''}
                        helperText={registerState && checkIsValid(registerData.streetName) ? t('not_empty') : ''}
                        value={registerData.streetName}
                        onChange={handleChangeRegister}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        style={{ width: '90%', marginLeft: 0 }}
                        id="houseNumber"
                        name="houseNumber"
                        label={`${t('company_number')}* `}
                        variant="outlined"
                        className={classes.textField}
                        size="small"
                        error={registerState ? checkIsValid(registerData.houseNumber) : ''}
                        helperText={registerState && checkIsValid(registerData.houseNumber) ? t('not_empty') : ''}
                        value={registerData.houseNumber}
                        onChange={handleChangeRegister}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        style={{ width: '90%', marginLeft: 0 }}
                        id="zipCode"
                        name="zipCode"
                        label={`${t('company_zip')}* `}
                        variant="outlined"
                        className={classes.textField}
                        size="small"
                        error={registerState ? checkIsValid(registerData.zipCode) : ''}
                        helperText={registerState && checkIsValid(registerData.zipCode) ? t('not_empty') : ''}
                        value={registerData.zipCode}
                        onChange={handleChangeRegister}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        style={{ width: '90%', marginLeft: 0 }}
                        id="city"
                        name="city"
                        label={`${t('company_location')}* `}
                        variant="outlined"
                        className={classes.textField}
                        size="small"
                        error={registerState ? checkIsValid(registerData.city) : ''}
                        helperText={registerState && checkIsValid(registerData.city) ? t('not_empty') : ''}
                        value={registerData.city}
                        onChange={handleChangeRegister}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        style={{ width: '90%', marginLeft: 0 }}
                        id="country"
                        name="country"
                        label={`${t('company_country')}* `}
                        variant="outlined"
                        className={classes.textField}
                        size="small"
                        error={registerState ? checkIsValid(registerData.country) : ''}
                        helperText={registerState && checkIsValid(registerData.country) ? t('not_empty') : ''}
                        value={registerData.country}
                        onChange={handleChangeRegister}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" color="textSecondary" style={{ fontSize: '12px' }}>
                        {`${t('data_privacy')}`}{' '}
                        <a href={`${process.env.REACT_APP_BASE_URL}datenschutzerklaerung?lang=${language}`}>
                          {`${process.env.REACT_APP_BASE_URL}datenschutzerklaerung?lang=${language}`}
                        </a>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl
                        required
                        error={registerState && !checkboxState1 ? true : ''}
                        component="fieldset"
                        className={classes.formControl}
                      >
                        <FormControlLabel
                          style={{
                            marginTop: 10,
                            marginBottom: 10,
                            width: '90%',
                            float: 'left',
                          }}
                          control={
                            <Checkbox
                              fullWidth
                              checked={checkboxState1}
                              onChange={(event) => setCheckboxState1(event.target.checked)}
                              name="checkedB"
                              color="primary"
                            />
                          }
                          label={
                            <Typography variant="body2" color="textSecondary" style={{ fontSize: '12px' }}>
                              <Trans
                                i18nKey="terms"
                                t={t}
                                components={[
                                  <a href={`${process.env.REACT_APP_BASE_URL}nutzungsbedingungen`}>
                                    {t('usage_terms')}
                                  </a>,
                                ]}
                              />
                            </Typography>
                          }
                        />
                        <FormHelperText>{registerState && !checkboxState1 ? t('accept_usage') : ''}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        style={{
                          marginTop: 10,
                          marginBottom: 10,
                          marginRight: 60,
                          float: 'right',
                          fontSize: '20px',
                        }}
                      >
                        {t('create_account')}
                      </Button>
                    </Grid>
                  </form>
                </Grid>

                <Grid item xs={6}>
                  <form onSubmit={handleLogin}>
                    <Grid item xs={12}>
                      <div
                        style={{
                          fontWeight: 700,
                          textTransform: 'uppercase',
                        }}
                      >
                        {t('login_form')}
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="emailAdress"
                        style={{ width: '90%', marginLeft: 0 }}
                        label={t('email')}
                        variant="outlined"
                        size="small"
                        error={loginState ? checkIsValid(loginData.emailAdress) : ''}
                        helperText={loginState && checkIsValid(loginData.emailAdress) ? t('not_empty') : ''}
                        name="emailAdress"
                        className={classes.textField}
                        value={loginData.emailAdress}
                        onChange={handleChangeSignIn}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        id="password"
                        style={{ width: '90%', marginLeft: 0 }}
                        label={t('password')}
                        name="password"
                        variant="outlined"
                        className={classes.textField}
                        size="small"
                        type="password"
                        error={loginState ? checkIsValid(loginData.password) : ''}
                        helperText={loginState && checkIsValid(loginData.password) ? t('not_empty') : ''}
                        value={loginData.password}
                        onChange={handleChangeSignIn}
                      />
                    </Grid>
                    {loginError && (
                      <Grid item xs={12}>
                        <div
                          style={{
                            width: '90%',
                            color: 'red',
                            fontWeight: 700,
                          }}
                        >
                          {t('wrong_username')}
                        </div>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Link
                        href="/password-reset"
                        style={{
                          width: '90%',
                          fontWeight: 700,
                          marginTop: 10,
                          marginBottom: 10,
                        }}
                      >
                        {t('forgot_pw')}
                      </Link>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        id="loginBtn"
                        type="submit"
                        style={{
                          float: 'right',
                          marginRight: 60,
                          marginTop: 10,
                          marginBottom: 10,
                          fontSize: '20px',
                        }}
                      >
                        {t('login')}
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <img width="100%" style={{ marginTop: '10%' }} alt="" src={mockUp} />
                    </Grid>
                  </form>
                </Grid>

                <Grid item xs={12}>
                  <div
                    style={{
                      width: '100%',
                      fontWeight: 700,
                      marginTop: '1%',
                      marginBottom: '1%',
                    }}
                  >
                    {t('collect_text')}
                    <div style={{ fontWeight: 500 }}>
                      <Trans
                        i18nKey="info_collect_text"
                        t={t}
                        components={[
                          <a href={`${process.env.REACT_APP_BASE_URL}datenschutzerklaerung?lang=${language}`}>
                            {t('data_privacy')}
                          </a>,
                        ]}
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </DialogContent>
      </Dialog>
    </div>
  );
}
