import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Unity, useUnityContext } from 'react-unity-webgl';
import Video from '../assets/video/DS_Loading_Loop_V2.mp4';
import NavigationBar from '../components/navigation/NavigationBar';

// eslint-disable-next-line func-names
window.alert = function () {};

const ShadowSimulator = () => {
  const { t } = useTranslation();

  const {
    unityProvider,
    sendMessage,
    addEventListener,
    isLoaded,
    loadingProgression,
    removeEventListener,
    requestFullscreen,
  } = useUnityContext({
    loaderUrl: `${window.location.origin}/build/sattler_schattensimulator.loader.js`,
    dataUrl: `${window.location.origin}/build/sattler_schattensimulator.data`,
    frameworkUrl: `${window.location.origin}/build/sattler_schattensimulator.framework.js`,
    codeUrl: `${window.location.origin}/build/sattler_schattensimulator.wasm`,
    streamingAssetsUrl: `${window.location.origin}/streamingassets`,
  });

  console.log(window.location.origin);

  function handleOnClickFullscreen() {
    requestFullscreen(true);
  }
  const unityRef = useRef(null);

  const initAndSendMessage = useCallback(() => {
    const language = new URLSearchParams(window.location.search).get('lang') || localStorage.getItem('i18nextLng');
    sendMessage('ScriptObject', 'UpdateLocalization', language);
  }, [sendMessage]);

  useEffect(() => {
    addEventListener('UnityNotification', initAndSendMessage);

    return () => {
      removeEventListener('UnityNotification', initAndSendMessage);
    };
  }, [addEventListener, sendMessage, removeEventListener, initAndSendMessage]);

  return (
    <Container disableGutters maxWidth="lg">
      <NavigationBar unityRef={unityRef} />
      <Unity ref={unityRef} unityProvider={unityProvider} className="unity-canvas" />
      {!isLoaded && (
        <Box width="1230" mr={1}>
          <Box top="5%" left={0} bottom={0} right={0} position="absolute" display="flex" justifyContent="center">
            <div>
              <video
                style={{ height: 600, border: '3px solid #f3f3f3' }}
                loop
                autostart
                autoPlay
                src={Video}
                type="video/mp4"
              >
                <track kind="captions" />
              </video>
            </div>
          </Box>
        </Box>
      )}
      {loadingProgression === 1 && <Button onClick={() => handleOnClickFullscreen()}>{t('fullscreen')}</Button>}
    </Container>
  );
};
export default ShadowSimulator;
